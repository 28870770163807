body {
  background-color: #121212;
}
.AppContainer {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  width: 100vw;
  overflow: hidden;

}
.picker-drop {
  border: 2px dashed rgb(204, 204, 204);
  border-radius: 5px;
  margin: 0px;
  padding: 20px;
}

.picker {
  z-index: 2000 !important;
}

.margin-button {
  margin-left: 5px;
}

.FilterSecond {
  display: inline;
  margin-left: 10px;
}

.white {
  color: white;
}

.bold_red {
  font-weight: bold;
  color: red !important;
}

.org-tokens-data {
  font-size: 0.8em;
}

.dod_banner {
  padding: 20px;
}

.dod_banner h1 {
  font-size: 24px;
  margin-top: 0;
}

.dod_banner p {
  margin-bottom: 10px;
}

.table-border {
  border: 1px solid gray;
  /* background-color: #eeeeee !important; */
}
.table-border td, .table-border th {
  padding: 10px;
  border: 1px solid gray;
}

.reg-page-mode {
  background-color: #121212;
  min-height: 100vh;
}

.reg-page-mode-header {
  background-color: #121212;
}
.reg-page-mode-content {
  background-color: white;
  background-position: 50% 56%;
  background-repeat: no-repeat;
  background-size: 100vw;
  padding: 50px;
  min-height: 400px;
  border-radius: 30px;
}

.reg-page-mode-footer {
  background-color: #121212;
  color: white;
  padding: 20px;
  text-align: center;
}

.reg-page-mode-header img {
  width: 80px;
  margin: 10px;
}

.reg-page-mode-header h1 {
  color: white;
  margin-bottom: 60px;
  margin-top: 0px;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.4;    
}

.tab-list {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  padding: 0;
  margin: 25px 10px;
}

.tab-content {
  margin-top: 25px;
  background-color: #eeeeee;
  border-radius: 5px;
  padding: 15px 10px;
  color: black;}

.tab-content a.gray {
  color: black !important;
}

.tab-item {
  display: flex;
  cursor: pointer;
  padding: 10px;
  background-color: #ddd;
  color: #1c2636;
  border-radius: 5px;
  margin: 5px;
  align-items: center;
  justify-content: center;
  width: 120px;
}

.tab-item.active {
  background-color: #212121;
  color: #fff;
}

.action_block {
  color: orangered;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
}

.comment_block {
  color: greenyellow;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
}

.token_count {
  display: inline-block;
}

.float_right {
  float: right;
}

.spinner {
  width: 30px;
  animation: spin 1s linear infinite;
}

.spinner-dark {
  width: 30px;
  animation: spin 1s linear infinite;
  filter: invert(1);
}

.inline-item {
  margin-right: 5px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.header-message {
  background-color: #3f7a47;
  color: #ffffff;
  height: 16px;
  line-height: 16px;
  font-size: 8pt;
  padding: 0 15px;
  border: 1px solid transparent;
  border-radius: 0;
  width: 100%;
  text-align: center;
  margin: 0;
}

.loading {
  text-align: center;
  font-size: 22pt;
  margin: 30px auto;  
}

.header_wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.header_logo {
  margin-top: 7px;
}

.header_logo, .header_inline {
  display: inline-block !important;
  padding: 10px;
  color: white;
}

.header_subscribe {
  display: inline-block;
  text-align: center;
  width: 100%;
  background-color: #212121;
  padding: 5px;
}

.header_subscribe a {
  color: white;
}

.header_inline {
  margin-top: 10px;
  display: flex;
  justify-content: end;
  align-items: end;
}

.header_block {
  position: relative;
  display: inline-block;
}

.account-selection-block {
  position: relative;
  top: 10px; /* Adjust the top position as needed */
  right: -10px;
  width: 190px; /* Adjust the width as needed */
  border-radius: 15px; /* Rounded corners */
  background-color: #121212;
  padding: 10px;
  z-index: 1;
}

.account-selection-block button, .account-selection-block a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: none;
  border: none;
  text-decoration: none;
  color: white;
  font-size: 10pt !important;
  padding-inline: 6px;
  text-align: left;
  margin-bottom: 10px;
}

.account-selection-block button svg {
  margin-left: 10px; /* Adjust the margin as needed */
}

.PromptButtonSmall {
  display: none;
}

.sidemenu-selection-block {
  position: absolute;
  right: 0;
  width: max-content; /* Adjust the width as needed */
  border-radius: 15px; /* Rounded corners */
  background-color: #121212;
  padding: 10px;
  z-index: 1;
}

.sidemenu-selection-block button, .account-selection-block a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: none;
  border: none;
  text-decoration: none;
  color: white;
  font-size: 11pt;
  padding-inline: 6px;
  text-align: left;
  margin-bottom: 10px;
}

.sidemenu-selection-block button svg {
  margin-left: 20px; /* Adjust the margin as needed */
}

.buttonNoBorder {
  background: none;
  border: none;
}

.buttonNoBorder .large {
  width: 200px;
  display:inline-block;
  white-space: nowrap;
  overflow: hidden;  
}

.prompt-container {   
  display: flex;   
  flex-wrap: wrap; 
}  

.prompt-item {   
  flex: 1 0 calc(33.33% - 20px);   
  margin: 10px;   
  cursor: pointer;
}  

.prompt-item-title {
  font-size: 12pt;
  clear: both;
  font-weight: bold;
  padding-top: 10px;  
  margin-bottom: 10px;  
}

.prompt-persona {
  margin: 20px 10px;
}

.prompt-item-persona {
  font-size: 10pt;
  float: right;
}

.prompt-item-public {
  font-size: 10pt;
  float: left;
  margin-top: -4px;  
}

.prompt-item:nth-child(3n+1) {
  clear: left; 
}

.api-keys-item {   
  margin: 10px;   
  box-sizing: border-box; 
  border-radius: 0.5rem;
  border-width: 1px;
  border-color: rgb(230 230 234);  
  background: rgb(233, 236, 239);
  color: black;
  padding: 15px;
  cursor: pointer;
}  

.api-keys-item-name {
  font-size: 12pt;
  clear: both;
  font-weight: bold;  
}

.plugin-container {   
  display: flex;   
  flex-wrap: wrap; 
}  

.plugin-item {   
  flex: 1 0 calc(33.33% - 20px);   
  margin: 10px;   
  box-sizing: border-box; 
  border-radius: 0.5rem;
  border-width: 1px; 
  cursor: pointer;
}  

.plugin-item-title {
  font-size: 12pt;
  clear: both;
  font-weight: bold;  
}

.plugin-category {
  margin: 20px 10px;
}

.plugin-item-category {
  font-size: 8pt;
  float: right;
  margin-top: -4px;  
}

.plugin-item-paid {
  font-size: 10pt;
  font-weight: bold;
}

.plugin-item:nth-child(3n+1) {
  clear: left; 
}

.markdown-div {
  font-size: 0.9em;
}

.special-scrollbar::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}

.special-scrollbar::-webkit-scrollbar-track {
  background: transparent !important;
}

.special-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888 !important;
  border-radius: 10px !important;
  border: 2px solid transparent !important;
}

.special-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555 !important;
}

/* Firefox */
.special-scrollbar {
  scrollbar-width: thin !important;
  scrollbar-color: #888 transparent !important;
}

.markdown-div pre::-webkit-scrollbar,
.react-syntax-highlighter::-webkit-scrollbar,
.react-syntax-highlighter pre::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}

.markdown-div pre::-webkit-scrollbar-track,
.react-syntax-highlighter::-webkit-scrollbar-track,
.react-syntax-highlighter pre::-webkit-scrollbar-track {
  background: transparent !important;
}

.markdown-div pre::-webkit-scrollbar-thumb,
.react-syntax-highlighter::-webkit-scrollbar-thumb,
.react-syntax-highlighter pre::-webkit-scrollbar-thumb {
  background-color: #888 !important;
  border-radius: 10px !important;
  border: 2px solid transparent !important;
}

.markdown-div pre::-webkit-scrollbar-thumb:hover,
.react-syntax-highlighter::-webkit-scrollbar-thumb:hover,
.react-syntax-highlighter pre::-webkit-scrollbar-thumb:hover {
  background-color: #555 !important;
}

/* Firefox */
.markdown-div pre,
.react-syntax-highlighter,
.react-syntax-highlighter pre {
  scrollbar-width: thin !important;
  scrollbar-color: #888 transparent !important;
}

.markdown-div h1, .markdown-div h2, .markdown-div h3, .markdown-div h4, .markdown-div h5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.markdown-div h1 {
  font-size: 1.2em;
}
.markdown-div h2 {
  font-size: 1.1em;
}
.markdown-div h3 {
  font-size: 1.0em;
  font-weight: bold;
  padding: 10px 0px;
}
.markdown-div h4 {
  font-size: 0.9em;
}
.markdown-div h5 {
  font-size: 0.8em;
}

.markdown-div div p {
  margin-top: 5px;
  margin-bottom: 5px;
}

.markdown-div li {
  margin-top: 5px;
  margin-bottom: 5px;
}

.container {
  padding-bottom: 20px;
}

.btn-border {
  border: 1px solid white !important;
}

.btn-blue {
  background-color: #315399 !important;
}

table {
  background-color: white;
}

.parsed_table {
  background-color: black !important;
  color: white;
  margin-bottom: 10px;
}

.parsed_table th,
.parsed_table td {
	padding: 10px;
	font-size: 10pt;
	border: 1px solid #E0E0E0;
}

.parsed_table th {
	font-weight: bold;
	text-align: left;
}

.parsed_table table pre code {
	font-size: 9pt;
}

.parsed_table table pre,
.parsed_table li pre {
	margin: 5px 0;
}

code {
  margin: 5px 0px 5px 0px;
  border-radius: 0.375rem;
}

/* Style the scrollbar */
/* ::-webkit-scrollbar {
  width: 10px;
  padding: 0;
  margin: 0;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background: lightgray;
  border-radius: 20px;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

.modal_div {
  font-size: 12pt;
}

.warning_message {
  background-color: white;
  margin: auto;
  padding: 5px;
  color: red;
}

.ReactModalPortal .btn-small {
  font-size: 1em;
  border-radius: .25rem;
  border: 1px solid white;
}
.ReactModalPortal .btn-small:hover {
  background-color: #315399;
  border-color: #315399;
}

.modal_header {
  margin: 20px;
  text-align: center;
  font-size: 16pt;
  font-weight: 700;
  line-height: 1.4;    
}

.modal-background {
  background: #121212 !important;
  padding: 20px;
  inset: 40px;
}

.modal_logo {
  width: 100px;
  float: left;
}

.modal_header_sec {
  font-size: 16pt;
  text-decoration: underline;
  text-align: center;
  padding: 5px;
}

.gray {
  color: #e9ecef
}

.gray:hover {
  color: #e9ecef
}

.download_link {
  background-color: #315399;
  color: #e9ecef;
  padding:6px;
  font-size:10pt;
  height: 35px;
  border: 1px solid #315399;
  color: #e9ecef;
  transition: background-color 0.3s;
  border-radius:5px;
  text-align: center;
  transition:ease 0.25s all;
  cursor:pointer;
  width: 80px;  
  margin-top: 10px;
  display: block;
}

.code_button {
  background-color: #315399;
  color: #e9ecef;
  padding:6px;
  font-size:10pt;
  height: 35px;
  border: 1px solid #315399;
  color: #e9ecef;
  transition: background-color 0.3s;
  border-radius:5px;
  text-align: left;
  transition:ease 0.25s all;
  cursor:pointer;
  margin: 7px;
  text-decoration: none !important;
}

.code_pre {
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

.reg-page-mode .btn-secondary {
  background-color: #315399;
  border-color: #315399;
}

.btn-secondary {
  background: none;
  border-color: white;
}

.btn-secondary:hover {
  background-color: #315399;
  border-color: #315399;
}

.chat-input-label-nonlarge {
  display: none;
}

.chat-input-label-large {
  display: inline;
}

.header_inline a {
  color: white !important;
}

.AppContent {
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  top: 80px; 
  position: absolute;
}

.AppContent.longer {
  top: 114px;
}

.AppContent.bot {
  top: 0 !important;
}

.sidemenu {
  overflow-y: auto;
  padding:10px;
}

.chatbox {
  /* border-radius: 16px; Rounded corners */
  /* background-color:#212121; */
  margin-right: 10px;
  flex: 1 1;
  position: relative;
}

@media (max-width: 1050px) {

  .modal_div {
    font-size: 10pt !important;
  }
  
  .modal_header, .modal_header_sec {
    font-size: 12pt !important;
  }
  
  .select-menu {
    font-size: 7pt !important;
    max-width: 95px !important;
  }
  
  .chat-input-menu {
    gap: 10px !important;
  }  
  .chat-input-label {
    display: none !important;
  }
  .chat-input-label-large {
    display: none !important;
  }
  .chat-input-label-nonlarge {
    display: inline !important;
  }
}

@media (max-width: 1470px) {
  .PromptButtonLarge {
    display: none;
  }
  
  .PromptButtonSmall {
    display: inline;
  }
}

@media (max-width: 901px) {
  .chatbox {
    margin: 0 !important;
    max-width: none;
  }
}

@media (max-width: 750px) {

  .workbook-menu span {
    font-size: 0.8em !important;
  }

  .chat-log-copy-btn {
    margin: 0 !important;
  }

  .Temperate_block {
    display: none !important;
  }

  .dod_banner {
    padding: 0px;
  }
  .dod_banner h1 {
    font-size: 18px;
    margin-top: 0;
  }
  
  .modal_div td {
    word-break: break-all;
  }
    
  .FilterSecond {
    display: block !important;
    margin-left: 0px !important;
  }

  .margin-button {
    margin-top: 5px;
  }

  .modal_div button {
    margin: 5px;
  }

  .modal_div input[name="searchDataset"] {
    margin: 5px;
  }

  .switchMode {
    display: none;
  }

  .reg-page-mode-header img {
    width: 80px;
    margin: 10px;
  }
  
  .reg-page-mode-header h1 {
    font-size: 30px;
    margin-bottom: 30px;
  }

  .tab-list {
    display: flow !important;
  }

  .tab-item {
    font-size: 8pt;
    width: 70px;
    display: inline-grid;
  }

  .chat-input-textarea {
    height: 115px !important;
    font-size: 11pt !important;
  }
  .Dataset_block {
    gap: 0px !important;
  }

  .account-selection-block {
    right: 30px;
  }

  .video-item {     
    width: 47% !important;   
    margin: 2px !important;
  }   
  .video-list {     
    max-width: 100%;   
  }   
  .video-info h3 {     
    font-size: 0.8rem !important;   
  }   
  .video-info p {     
    font-size: 0.8rem;   
  } 

  .modal_logo {
    float: unset;
  }

  .modal_list {
    margin-left: unset;
  }

  .prompt-item {   
    flex: unset;
  }
  .plugin-item {   
    flex: unset;
  }

  .PromptButtonLarge {
    display: none;
  }
  
  .PromptButtonSmall {
    display: inline;
  }
  
  .edit-label {
    font-size: 12pt !important;
  }
  .edit-input {
    font-size:10pt !important;
  }

  .buttonNoBorder .large {
    width: 150px;
  }
   
  .AppContent.longer {
    top:110px !important;
  }

  .header_member, .header_nonmember {
    height: 30px !important;
  }

  .header_logo, .header_inline {
    padding: 0 !important;
  }

  .sidemenu {
    display:none;
  }
  .submit {
    margin: auto 5px !important;
  }
  .chat-input-menu {
    gap: 4px !important;
    padding-left: 10px !important;
    padding-right: 0 !important;
    padding-top: 10px !important;
  }  
  .chat-input-label {
    display: none !important;
  }
  .chat-input-label-large {
    display: none !important;
  }
  .chat-input-label-nonlarge {
    font-size: 8pt !important;
    display: inline !important;
  }
  .chat-message-center {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .message {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .user-list-menu-item {
    padding: 3px !important;
  }  
}

/* Media query for screens smaller than 350px */
/* @media (max-width: 350px) {
  html {
    font-size: 90%;
  }
} */

select {
  border-radius: 0.375rem;
  background-color: #e9ecef;
}

.select-menu {
  height: 30px;
  font-size: 10pt
}

.chat-log-copy-btn:hover {
  color: white;
}

.clicked {
  background-color:burlywood !important
}

/* .file-paperclip svg {
  height: 20px;
  width: 20px;
} */

.chat-log-file-btn {
  display: inline-block;
  border: none;
  border-radius: 5px;
  background-color: none;
  width: 30px;
  height: 30px;
  background-size: cover !important;
  cursor: pointer;
  padding: 0;
}

.red-background {
  background-color: red !important;
}

.chat-log-copy-btn {
  padding:4px;
  border: none;
  background: none;
  transition:ease 0.25s all;
  cursor:pointer;
  margin: 5px;
  color: white;
}

.chat-log-copy-btn img {
  width: 17px;
}

.chat-log-copy-btn svg {
  width: 17px;
  height: 17px;
}

.btn-with-bg:hover {
  color: white;
}


.btn-with-bg {
  padding:9px;
  border: none;
  background-color: #315399;
  border-radius:5px;
  transition:ease 0.25s all;
  cursor:pointer;
  margin: 5px;
  color: white;
}

.btn-with-bg:disabled {
  background-color: gray;
  color: white;
  cursor: not-allowed;
}

.chat-log-followup-btn:hover {
  color: white;
}

.chat-log-followup-btn {
  padding-top:4px;
  padding-bottom:4px;
  padding-left:8px;
  padding-right:8px;
  font-size:13px;
  border: none;
  background-color: #315399;
  border-radius:8px;
  text-align: left;
  transition:ease 0.25s all;
  cursor:pointer;
  margin: 5px !important;
  color: white;
}

.chat-log-followup-btn:disabled {
  background-color: gray;
  color: white;
  cursor: not-allowed;
}

.chat-log-references {
  background-color: #121212;
  margin: 20px;
}
.chat-log-references-title {
  color: #e9ecef;
  padding: 10px;
  text-decoration: underline;
}
.chat-log-references-item {
  padding: 10px;
}
.chat-log-references-item a {
  -ms-word-break: break-all;
  word-break: break-all;
  white-space: pre-wrap;
}

.header_member, .header_nonmember {
  background-color: #315399;
  color: white;
  text-align: center;
  padding: 5px;
}
.header_member a, .header_nonmember a {
  color: white;
}

.edit-label {
  font-size: 13pt;
}
.edit-input {
  width: 300px;
  background-color:#e9ecef;
  width:90%;
  padding:12px;
  border-radius:5px;
  color:#282c34;
  font-size:1em;
  border:none;
  outline:none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.side-menu-button {
  display: flex;   
  padding:6px 4px;
  font-size:10pt;
  height: 35px;
  transition: background-color 0.3s;
  border-radius:5px;
  text-align: left;
  transition:ease 0.25s all;
  cursor:pointer;
  margin: 7px;
  justify-content: space-between;
}

.side-menu-button-content {
  white-space: nowrap;
  overflow: hidden;
  padding-left: 10px;
  text-overflow: ellipsis;
}

.side-menu-button-img {
  margin: 5px 7px;
}

.side-menu-button-main {
  padding:6px;
  font-size:13pt;
  background-color: none;

  text-align: left;
  cursor:pointer;
  margin-left: 7px;
}

.side-menu-button-main svg {
  border: 1px solid;
  float: right;
  margin-top: 5px;
  border-radius: 5px;
}

.centered {
  text-align: center !important;
}

.sidecar-button-group {
  margin-left: 0px;
  margin-top: 1px;
}
.side-menu-wrapper {
  position: relative;
  display: inline-block;
}

.side-menu-container {
  text-align: right;
}

.side-menu-button.selected {
  outline: none;
  border: none;
}
  
.side-menu-button.notselected {
  border: none;
}

.side-menu-button:hover {
  outline: none;
}

.sidecar-button {
  padding:3px;
  background-color: #315399;
  border:1px solid #315399;
  border-radius:5px;
  text-align: center;
  transition:ease 0.25s all;
  cursor:pointer;
  margin-left: 2px;
}

.sidecar-button svg, .sidecar-button img {
  width: 12px
}

.sidecar-button-nobg {
  padding:3px;
  background: none;
  border: none;
  text-align: center;
  cursor:pointer;
  margin-left: 2px;
}

.sidecar-button-nobg {
  height: 15px;
  width: 15px;
}

.tab-inactive {
  background: none;
  border: none;
}
.tab-active, .tab-inactive:hover {
  background: none;
  border: 1px solid white;
}
.tab-active:focus {
  background: none;
  border: 1px solid white;
}


.side-menu-button span, .side-menu-button-main span {
  padding-left:4px;
  padding-right:4px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.video-container {   
  display: flex;   
  flex-wrap: wrap;   
  justify-content: center; 
}  
.video-list {   
  display: flex;   
  flex-wrap: wrap;   
  justify-content: center;   
  margin: 0 auto; 
}  
.video-item {   
  width: 200px;   
  margin: 10px;   
  border-radius: 5px;   
  overflow: hidden;   
  cursor: pointer; 
}  
.video-item.active {   
  border: 2px solid #f00; 
}  
.video-item img {   
  width: 100%;   
  height: auto; 
}  
.video-info {   
  padding: 10px; 
}  
.video-info h3 {   
  font-size: 1.2rem;   
  margin: 0 0 10px; 
}  
.video-info p {   
  font-size: 1rem;   
  margin: 0; 
}  
.video-player {   
  width: 100%;   
  height: 0;   
  padding-bottom: 56.25%;   
  position: relative; 
}  
.video-player iframe {   
  position: absolute;   
  top: 0;   
  left: 0;   
  width: 100%;   
  height: 100%; 
}  

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.chat-input-holder {
  padding: 12px 10px;
  position:absolute;
  bottom:0;
  left:0;right:0;
}

.chat-input-holder-item {
  border-radius: 0 0 0.375rem 0.375rem;
  display: flex;
  flex-direction: column;
}

.textarea-buttons-block {
  align-items: center;
  gap: 5px;
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}

.chat-input-textarea {
  background-color:white;
  width:100%;
  padding: 10px;
  border-radius:5px;
  height: 160px;
  color:#282c34;
  font-size:1em;
  border:none;
  outline:none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.custom-intro-prompt-textarea {
  background-color:white;
  width:100%;
  padding:12px;
  border-radius:5px;
  height: 200px;
  color:#282c34;
  font-size:1em;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #e9ecef;
}

.App-link {
  color: #61dafb;
}

.dall-e-image {
  width: 30%;
  padding: 3px;
}

.file-info {
  font-size: 14px;
  margin-bottom: 0px;
  margin-top: 3px;
  width: 100%;
  height: 25px;
  white-space: nowrap;
}

.chat-log-file {
  color: white;
  font-size: 14px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-top: 3px;
  width: 100%;
  height: 25px;
}

/* Style for the remove button */
.remove-button {
  background-color: #315399; /* Red background */
  border: none; /* Remove border */
  color: white; /* White text */
  padding: 4px 4px; /* Some padding */
  text-align: center; /* Centered text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Make it inline */
  font-size: 12px; /* Increase font size */
  margin: 4px 2px; /* Some margin */
  width: 20px;
  height: 20px;
  cursor: pointer; /* Add a pointer cursor on hover */
  border-radius: 50%; /* Rounded corners */
}

.chat-log.bot {
  bottom: 275px !important;
}

.chat-input-label, .chat-input-label-large {
  font-size: 11pt;
}

.chat-message-center {
  margin-left:auto;
  margin-right:auto;
  display:flex;
  padding:12px 24px;  
}
.avatar {
  background:#e9ecef;
  border-radius:50%;
  width: 40px;
  height: 40px;
  display:flex;
  align-items:center;
  justify-content:center;
  padding:5px;
  font-size:14px;
  color:#444654;
}
.avatar.chatgpt {
  border-radius:50%;
  min-width: 40px;
  min-height: 40px;
  padding:5px;
  color:#e9ecef;
}
.message {
  padding-left: 40px;
  padding-right: 40px;
  margin: auto 0;
}

.message a {
  color: white;
  -ms-word-break: break-all;
  word-break: break-all;
  white-space: pre-wrap;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.select-models {
  border: 1px solid #e9ecef;
  padding:12px;
  border-radius:5px;
  color:#e9ecef;
  background:transparent;
  outline:none;
  cursor:pointer;
  max-width:100%;
  min-width:100%;
}
.select-models option {
  background:black;
  color:#e9ecef;
}

.button-picker {
  border:none;
  padding:6px 12px ;
  border-radius:5px;
  color:#e9ecef;
  background:#444654;
  margin-top:6px;
  cursor: pointer;
}
.button-picker:hover {
  background:#626473;
}

.info {
  opacity:0.66;
  font-size:0.8em;
  margin-top:6px;
}

.side-label {
  font-size:0.9em;
  margin-top:1em;
  margin-bottom:0.5em;
  padding-bottom:0em;
  display:block;
}

.btn-small {
  background: none;
	border: none;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  background: none;
  display:flex;
  align-items:center;
  justify-content:center;
  font-size:14px;
  cursor: pointer;
}

.btn-small svg {
  flex-shrink: 0;
}

.submit {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  background-color: #315399;
  display:flex;
  align-items:center;
  justify-content:center;
  padding:5px 12px;
  font-size:14px;
  color:#e9ecef;
  border-radius:5px;
  cursor: pointer;
}
.submit:hover {
  background-color: #315399;
}

.katex {
  margin-right: 7px;
}

.katex .katex-mathml {
  position: fixed !important;
}

.katex-display>.katex {
  text-align: left !important;
}

.settings-drawer-text {
  font-size: 11pt;
}

/* Workbook */

/* SideMenu.css */

/* General Styles for SideMenu */
.workbook-side-menu {
  padding: 10px;
  overflow-y: auto;
}

.workbook-side-menu-list-button {
  margin-top: 10px;
}

.workbook-side-menu-source {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.workbook-side-menu-source-icon {
  margin-right: 10px;
  font-size: 1.5em;
}

.workbook-side-menu-source-name {
  flex-grow: 1;
  font-weight: bold;
  font-size: 0.9em; /* Reduced font size */
  word-wrap: break-word; /* For older browsers */
  overflow-wrap: break-word; /* For modern browsers */
  white-space: normal; /* Ensure normal wrapping */
  width: 110px;
}

.workbook-side-menu-button-source-group {
  display: flex;
  align-items: center;
}

.workbook-side-menu-buttonNoBorder {
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 1.2em;
  transition: color 0.3s;
}

.workbook-side-menu-buttonNoBorder:hover {
  color: #ff4d4d;
}

/* Dialog Styles */
.workbook-side-menu-dialog .MuiDialog-paper {
  background-color: #f5f5f5;
  color: #333;
  border-radius: 10px;
  padding: 20px;
}

.workbook-side-menu-dialog .MuiDialogTitle-root {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

.workbook-side-menu-dialog .MuiDialogContent-root {
  font-size: 1em;
  line-height: 1.5;
}

.workbook-side-menu-dialog .MuiDialogActions-root {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
}

.workbook-side-menu-dialog .MuiButton-containedError {
  background-color: #ff4d4d;
  color: #ffffff;
}

.workbook-side-menu-dialog .MuiButton-containedError:hover {
  background-color: #e60000;
}

.workbook-side-menu-dialog .MuiButton-root {
  margin-left: 10px;
}

/* workbook List */

.workbook-item {
  width: 100%;
  background-color: #f5f5f5; /* Light grey background */
  margin-bottom: 1rem;
  border: 1px solid #ddd; /* Light grey border */
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.workbook-item:hover {
  background-color: #e0e0e0; /* Slightly darker grey on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow on hover */
}

.workbook-item-public {
  display: flex;
  justify-content: space-between; /* Space between elements */
  align-items: center;
  border-bottom: 1px solid #ddd; /* Border between sections */
}

.workbook-item-title {
  font-size: 1.25rem; /* Larger font size for title */
  font-weight: bold;
  color: #333; /* Dark grey text */
}

.workbook-item-description {
  padding: 1rem;
}

.workbook-list-button-nobg {
  background: none;
  border: none;
  cursor: pointer;
  color: #ff0000; /* Red color for delete icon */
  transition: color 0.3s ease;
  margin-left: auto; /* Push the button to the right */
}

.workbook-list-button-nobg:hover {
  color: #cc0000; /* Darker red on hover */
}


.workbook-menu {
  height: 50px;
  background-color: #121212;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid #505050;
}

.workbook-menu span {
  font-size: 1rem; /* Larger font size for the title */
  color: #ffffff; /* White text for better visibility */
  margin: 0 15px; /* Spacing around the title */
}

.link-button {
  color: white; /* Link color */
  text-transform: none; /* Disable uppercase transformation */
  padding: 0; /* Remove padding */
  min-width: auto; /* Remove minimum width */
  font-size: 0.875rem; /* Smaller font size */
  text-decoration: underline; /* Underline text */
}

.link-button:hover {
  background-color: transparent; /* Remove background on hover */
  text-decoration: none; /* Remove underline on hover */
}
/* General Dialog Styles */
.workspace-dialog-title {
  padding: 16px;
  font-size: 1.25rem;
  font-weight: bold;
}

.workspace-card-content {
  padding: 16px;
}

.workspace-card-content-title {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 8px;
}

.workspace-card-content-value {
  font-size: 0.875rem;
}

.workspace-card-content-value button {
  margin: 10px;
}

.workspace-icon-button {
  color: #333;
}

.workspace-icon-button:hover {
  color: #007bff;
}

.workbook-side-menu-memo {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.workbook-side-menu-memo-icon {
  margin-right: 10px;
  font-size: 1.5em;
}

.workbook-side-menu-memo-name {
  flex-grow: 1;
  font-weight: bold;
  font-size: 0.9em; /* Reduced font size */
  word-wrap: break-word; /* For older browsers */
  overflow-wrap: break-word; /* For modern browsers */
  white-space: normal; /* Ensure normal wrapping */
  width: 110px;
}

.workbook-side-menu-button-memo-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.workbook-side-menu-buttonNoBorder {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  transition: color 0.3s;
}

.workbook-side-menu-buttonNoBorder:hover {
  color: #ff0000; /* Change to your desired hover color */
}

.workbook-item-flex-container {
  display: flex;
  align-items: center; /* Optional: Align items vertically centered */
}

.workbook-item-mainblock {
  flex-grow: 1;
}

.workbook-item-deleteblock {
  width: 50px;
  padding-right: 10px;
  display: flex;
  justify-content: center; /* Optional: Center the delete button horizontally */
  align-items: center; /* Optional: Center the delete button vertically */
}

.markdownrenderer-div a {
  color: white !important;
}

/* ADMIN */

.user-list-menu {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  justify-content: space-around;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-list-menu-item {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  flex: 1 1 auto; /* Allow items to grow and shrink as needed */
  text-align: center; /* Center align text for better appearance */
}

.user-list-menu-item:hover {
  background-color: #e2e6ea;
}

.user-list-h1 {
  color: white;
  margin: 0 !important;
  font-size: 1.5em !important;
  text-align: left !important;
}

.user-list-table-parent {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
}

.user-list-table {
  font-size: 14px !important;
  width: 100%;
  border-collapse: collapse;
}

.compact-cell {
  padding: 4px 8px !important; /* Reduced padding */
  font-size: 0.75rem !important; /* Smaller font size */
}

.table-cell-wrap {
  white-space: normal;       /* Allows line breaks */
  word-wrap: break-word;     /* For older browsers */
  overflow-wrap: anywhere;   /* Modern approach for better line breaking */
}

.user-list-reg-page-mode-content {
  padding: 10px !important;
}

.chatbox-canvas {
  /* border-radius: 16px; Rounded corners */
  /* background-color: #212121; */
  margin-right: 10px;
  flex: 1; /* 1/3 of the space */
}

.markdown-renderer-canvas {
  flex: 2; /* 2/3 of the space */
}

.prose-mirror {
  border: none;
  outline: none;
  cursor: text;
  padding: 8px;
  min-height: 120px;
  flex: 1;
  width: "100%";
  overflow: "auto";
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word; 
}

.prose-mirror .ProseMirror {
  outline: none;
  max-width: 100%;
  max-height: 100%;
  overflow-y: auto;
  padding-right: 20px;
}

.ProseMirror pre code {
  background-color: #555;
  padding: 0.175em;
  font-family: Menlo, Monaco, "Courier New", monospace;
  border-radius: 4px;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word; 
}

.prose-mirror  .ProseMirror h1 {
  font-size: 1.8em;
}

.prose-mirror  .ProseMirror h2 {
  font-size: 1.6em;
}

.prose-mirror  .ProseMirror h3 {
  font-size: 1.4em;
}

.prose-mirror  .ProseMirror h4 {
  font-size: 1.3em;
}

.prose-mirror  .ProseMirror h5 {
  font-size: 1.15m;
}

.prose-mirror  .ProseMirror h6 {
  font-size: 1em;
}

.prose-mirror  .ProseMirror p {
  font-size: 0.9em;
}

.prose-mirror .ProseMirror:focus,
.prose-mirror .ProseMirror.ProseMirror-focused {
  outline: none !important; 
  box-shadow: none;
}

.editor-placeholder {
  pointer-events: none;
  color: #999;
  user-select: none;
}

.highlight-line {
  background-color: rgba(0, 255, 0, 0.2) !important; /* Light green background */
}

.login-form {
  color: white
}

.login-form input, .login-form select {
  background-color: #212121 !important;
  border-color: #BBBBBB;
  color: white !important;
  border-radius: 10px;
}

.login-form label {
  color: white;
  font-size: 14px;
  margin-bottom: 4px;
}

.login-form-button {
  background-color: white !important;
  color: black !important;
  width: 100%;
  font-weight: bold;
  font-size: 0.9em;
  border-radius: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.login-form-button-blue {
  background-color: #11307E !important;
  color: white  !important;
  border: #11307E 1px solid !important;
  width: 100%;
  font-weight: bold;
  font-size: 0.9em;
  border-radius: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.login-form-button-secondary {
  background-color: transparent !important;
  color: white !important;
  border: white 1px solid !important;
  width: 100%;
  font-weight: bold;
  font-size: 0.9em;
  border-radius: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.login-form-button:hover {
  background-color: #BBBBBB !important;
}

.login-form-button-secondary:hover {
  background-color: #444444 !important;
}

.login-form-button-blue:hover {
  background-color: #001f5c !important;
  border: #001f5c 1px solid !important;
}

.input-wrapper {
  position: relative;
}

.input-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #bbbbbb;
  font-size: 16px;
}

.login-form-footer{ 
  color: white;
  padding: 20px;
  text-align: center;
}

.space-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  background-size: cover;
  height: calc(100vh - 16px); /* Full height for consistency */
  overflow: hidden; /* Prevent unwanted scrolling */
  border-left: white 1px solid; /* White border on the right */
}

.space-image img {
  max-width: 70%;
  max-height: 100%; /* Ensure the image does not exceed the container's height */
  height: auto;
  width: auto; /* Ensure the image scales proportionally */
}